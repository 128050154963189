<template>
  <smart-popup
    :value="value"
    v-on="$listeners"
  >
    <reminder-detail
      v-if="value"
      :tab="tab"
      :reminderType="document.type"
      preview-mode
    />
  </smart-popup>
</template>
<script>
import tabController from '@/tabController'

export default {
  components: {
    SmartPopup: () => import('@/components/smart-popup'),
    ReminderDetail: () => import('@/pages/account/active-task/reminder/reminder-detail')
  },
  watch: {
    value (value) {
      if (value) {
        const tabDocument = tabController.getTabFromDocumentType(this.document.type)

        if (tabDocument) {
          this.tab = tabController.create(tabDocument, { documentId: this.document.id })
        }
      }
    }
  },
  data () {
    return { tab: null }
  },
  props: {
    value: Boolean,
    document: Object
  }
}

</script>
